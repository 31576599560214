import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { take, map, tap } from 'rxjs/operators';

export interface UserCredentials {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  gender: string;
  birthdate: Date;
  userType: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User = null;
  email = '';
 
  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) {
    this.afAuth.authState.subscribe(res => {
      this.user = res;
      if (this.user) {
        this.db.doc(`users/${this.currentUserId}`).valueChanges().pipe(
          tap(res => {
            this.email = res['email'];
          })
        ).subscribe();
      }
    })
  }
 
  signUp(credentials: UserCredentials) {
    return this.afAuth.createUserWithEmailAndPassword(credentials.email.trim().toLowerCase(), credentials.password)
      .then((data) => {
        return this.db.doc(`users/${data.user.uid}`).set({
          firstName: credentials.firstName,
          lastName: credentials.lastName,
          email: data.user.email.trim().toLowerCase(),
          gender: credentials.gender,
          birthdate: credentials.birthdate,
          userType: 'Student',
          created: firebase.firestore.FieldValue.serverTimestamp()
        });
      });
  }

  isEmailAvailable(email) {
    return this.db.collection('users', ref => ref.where('email', '==', email).limit(1)).valueChanges().pipe(
      take(1),
      map(user =>{
        return user;
      })
    );
  }
 
  signIn(credentials: UserCredentials) {
    return this.afAuth.signInWithEmailAndPassword(credentials.email.trim().toLowerCase(), credentials.password);
  }
 
  signOut() {
    return this.afAuth.signOut();
  }

  detroyCurrentUser(){
    const user = firebase.auth().currentUser;

    return user.delete()
  }
 
  resetPw(email) {
    return this.afAuth.sendPasswordResetEmail(email);
  }
 
  updateEmail(email, authEmail, authPass) {
    return this.afAuth.signInWithEmailAndPassword(authEmail, authPass).then(async (userCredential) => {
        await userCredential.user.updateEmail(email)
    }).then(()=>{
      return this.db.doc(`users/${this.currentUserId}`).update({
        email
      });
    })    
  }
 
  updatePassword(password, authEmail, authPass) {
    return this.afAuth.signInWithEmailAndPassword(authEmail, authPass).then(async (userCredential) => {
        await userCredential.user.updatePassword(password)
    })  
  }

  getCurrentUser(): AngularFirestoreDocument<User>{
      return this.db.collection('users/').doc(this.currentUserId);
  }

  getCurrentUserId(){
    return this.currentUserId
  }

  resetPassword(email: string) {
    var auth = firebase.auth();
    return auth.sendPasswordResetEmail(email)
  }

  get authenticated(): boolean {
    return this.user !== null;
  }
 
  get currentUser(): any {
    return this.authenticated ? this.user : null;
  }
 
  get currentUserId(): string {
    return this.authenticated ? this.user.uid : '';
  }
}
