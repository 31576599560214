import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ResponsiveService } from 'src/app/services/responsive.service';


@Injectable({
  providedIn: 'root'
})
export class PwaGuard implements CanActivate {
  screenType = this.responsive.platformString;

  constructor(private navCtrl: NavController, private responsive: ResponsiveService){}

  canActivate(): boolean {

    // const isPWAInstalledOnDesktop: boolean = document.getElementsByClassName('plt-pwa').length > 0;
    // if(!isPWAInstalledOnDesktop){
    //   this.navCtrl.navigateRoot('/pwa');
    //   return false;
    // } else if (this.screenType == 'mobile') {
      return true;
    // }
  }
  
}
