import { AuthService } from 'src/app/services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Platform } from '@ionic/angular';
//import { Firebase } from '@ionic-native/firebase/ngx';
// import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor
} from '@capacitor/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  constructor(private platform: Platform,
    private router: Router,
    private afs: AngularFirestore,
    private auth: AuthService,
    private alertCtrl: AlertController) { }
  // constructor(private firebase: Firebase,
  //   private platform: Platform) { }

  initPush() {
    if (Capacitor.platform !== 'web') {
      this.registerPush();
    }
  }

  private registerPush() {
    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        this.saveToken(token['value']);
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotification) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: PushNotificationActionPerformed) => {
        const pushNotification = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (pushNotification.type == 'chat') {
          this.router.navigateByUrl(`/chat/${pushNotification.group}`);
        }else{
          this.router.navigateByUrl(`/tabs/tabs/calendar/show/${pushNotification.group}/${pushNotification.event}`);
        }
      }
    );
  }

  private saveToken(token) {
    if (!token) return;
    const devicesRef = this.afs.collection('devices');

    const data = {
      token,
      userId: this.auth.currentUserId
    };

    return devicesRef.doc(this.auth.currentUserId).set(data);
  }
}