import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { PwaGuard } from './guards/pwa.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/sessions/login/login.module').then( m => m.LoginPageModule),
    canActivate: [PwaGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/sessions/signup/signup.module').then( m => m.SignupPageModule),
    canActivate: [PwaGuard]
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule),
    canActivate:[LoginGuard, PwaGuard]
  },
  {
    path: 'chat/:id',
    loadChildren: () => import('./pages/chat/show/show.module').then( m => m.ShowPageModule),
    canActivate: [PwaGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/sessions/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule),
    canActivate: [PwaGuard]
  },
  {
    path: 'pwa',
    loadChildren: () => import('./pages/pwa/pwa.module').then( m => m.PwaPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
